import styled from 'styled-components'
import device from '../lib/mediaQueries'

const DefaultSection = styled.div`
  padding: 40px 5%;
  display: block;

  ${device.laptop} {
    padding: 40px 15%;
  }
`

export default DefaultSection
