import React from 'react'
import styled from 'styled-components'
import 'typeface-space-mono'
import Layout from '../components/Layout'
import DefaultSection from '../components/DefaultSection'
import DefaultLink from '../components/DefaultLink'
import DifferenceObject from '../components/DifferenceObject'
import device from '../lib/mediaQueries'
import { main } from '../lib/colors'

const PaddedSection = styled(DefaultSection)`
  margin-bottom: 4vh;
  ${device.tablet} {
    margin-top: 4vh;
    margin-bottom: 10vh;
  }
`

const MainTitle = styled.h1`
  font-size: 35px;
  margin-bottom: 5px;

  ${device.tablet} {
    font-size: 42px;
  }
`
const Subtitle = styled.span`
  font-size: 18px;
  line-height: 22px;
  max-width: 100%;
  ${device.tablet} {
    max-width: 50%;
    line-height: 28px;
    font-size: 20px;
  }
`

const Section = styled.section`
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  flex-wrap: wrap;
  padding: 0px;
`
const Columns = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px;
  margin: 4vh 0px;
  ${device.tablet} {
    margin: 12vh 0px 6vh 0px;
  }
`

const Column = styled.div`
  padding: 25px 0px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  ${device.tablet} {
    width: 50%;
    padding: 20px;
  }
`

const Difference = styled(DifferenceObject)`
  width: 300px;
  height: 300px;
`

const SectionTitle = styled.h2`
  font-size: 1.05em;
  line-height: 1.05em;
  padding: 3px;
  margin-top: 0px;
  margin-bottom: 16px;
  color: ${(props) => props.theme.background};
  background-color: ${(props) => props.theme.highlight};
  display: block;
  align-self: flex-start;
`

const List = styled.ul`
  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 8px;
  padding: 0px;
`

const Item = styled.li`
  margin-bottom: 24px;
  margin-left: 0px;
  &::before {
    content: '>';
    font-weight: bold;
    margin-right: 8px;
    fonts-size: 10px;
    color: #f2bf15;
  }
`

const MeSection = styled(Section)`
  flex-direction: column-reverse;
  ${device.tablet} {
    flex-direction: row;
  }
`

const Inverted = styled.div`
  background-color: ${(props) => props.theme.main};
  color: ${(props) => props.theme.background};
  padding: 20px;
  box-sizing: border-box;
  width: 100%;

  &::selection {
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.main};
  }

  ${device.tablet} {
    width: 65%;
  }
`

const Socials = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 0px;
  margin-top: 20px;

  flex-direction: column;
  justify-content: center;

  ${DefaultLink} {
    margin-right: 12px;
    margin-bottom: 5px;
  }

  ${device.tablet} {
    padding: 5px 20px;
    width: 35%;
    margin-top: 0px;
  }
`

const SocialsTitle = styled.h2`
  display: block;
  font-size: 1.1em;
  margin-top: 0px;
  margin-bottom: 0px;
`

const IndexPage = () => (
  <>
    <Layout theme={main} title="About">
      <PaddedSection>
        <Section>
          <MainTitle>Lalo Martínez</MainTitle>
          <Subtitle>Designer. Developer. Question asker.</Subtitle>
        </Section>
        <Columns>
          <Column>
            <SectionTitle>Currently</SectionTitle>
            <List>
              <Item>
                Designing and building at{' '}
                <DefaultLink href="https://quora.com">Quora</DefaultLink>
              </Item>
              <Item>
                Hiding passwords in{' '}
                <DefaultLink href="https://solve63.com">63</DefaultLink>
              </Item>
            </List>
          </Column>
          <Column>
            <SectionTitle>Before that</SectionTitle>
            <List>
              <Item>
                Gave a{' '}
                <DefaultLink href="https://youtu.be/5CwZ_4eTDzc">
                  talk
                </DefaultLink>{' '}
                at Figma Config about the core principles behind designing for
                machine learning.
              </Item>
              <Item>
                Co-founded and led design at Youles, building what we thought
                personal finance managers should've been from the beginning.
              </Item>
              <Item>
                Co-founded and led design at Ment, where we built and helped
                some very talented entrepreneurs build and launch products in
                Mexico and the US for 6+ years.
              </Item>
            </List>
          </Column>
        </Columns>

        <MeSection>
          <Socials>
            <SocialsTitle>Reach out</SocialsTitle>
            <span>
              <DefaultLink href="https://twitter.com/lalomts">
                twitter
              </DefaultLink>
              <DefaultLink href="https://github.com/lalomts">
                github
              </DefaultLink>
              <DefaultLink href="https://dribbble.com/lalo">
                dribbble
              </DefaultLink>
              <DefaultLink href="https://www.linkedin.com/in/emrtnzs/">
                linkedin
              </DefaultLink>
            </span>
          </Socials>
          <Inverted>
            <strong>We can talk about:</strong> puzzles, singing, salsa dancing,
            books, designing for ML, startups, Latin America (or anything you
            want!)
          </Inverted>
        </MeSection>

        <Difference maxTop={30} maxLeft={60} />
      </PaddedSection>
    </Layout>
  </>
)

export default IndexPage
