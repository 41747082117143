import styled from 'styled-components'

const DefaultLink = styled.a`
  color: ${(props) => props.theme.main};
  display: inline-block;
  position: relative;
  align-self: flex-start;
  text-decoration: none;
  padding: 2px 2px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  z-index: 0;

  &:hover {
    color: ${(props) => props.theme.background};
    font-weight: bold;
    z-index: 10;
  }

  &::after {
    content: ' ';
    background-color: ${(props) => props.theme.highlight};
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
    z-index: -1;
  }

  &:hover::after {
    height: 100%;
    background-color: ${(props) => props.color || props.theme.highlight};
  }
`

export default DefaultLink
