/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import device from '../lib/mediaQueries'

export const randomPercentage = (maxNumber) =>
  `${Math.random() * (maxNumber || 50)}%`

const move = keyframes`
    to {
      transform: translate3d(${randomPercentage(200)}, ${randomPercentage(
  200,
)},0) rotate(250deg);
    }
`

const Difference = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  position: absolute;
  width: 250px;
  height: 250px;
  background-color: ${(props) => props.theme.inverted};
  border-radius: 50%;
  mix-blend-mode: difference;
  pointer-events: none;
  animation: ${move} 120s linear alternate infinite;
  z-index: 10;

  ${device.laptop} {
    width: 350px;
    height: 350px;
  }
`

const DifferenceObject = ({ maxTop, maxLeft, ...rest }) => (
  <Difference
    {...rest}
    top={randomPercentage(maxTop)}
    left={randomPercentage(maxLeft)}
  />
)

DifferenceObject.propTypes = {
  style: PropTypes.shape,
  maxLeft: PropTypes.number,
  maxTop: PropTypes.number,
}

export default DifferenceObject
